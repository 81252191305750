import React from 'react';

import SEO from 'components/seo';
import Layout from 'components/layout';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found | Front End Developer | Jose D. Santos IV" />
    <div>
      <p> Not found</p>
    </div>
  </Layout>
);

export default NotFoundPage;
